
:root {
    --gray:#F9FAFC;
    --navy: #20285A;
    --brand-blue: #458CCB;
    --lightblue: #EAF2F9;
    --black: #000;
    --dimgray:#EAECF0;
}

.filter-wrapper {
    background-color: #EAECF0;   
    display: flex;     
    align-items: center;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 12px;
    font-size: 15px;

}

meanScore {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.75rem;
    position: relative;

    .bp3-intent-warning.bp3-callout-icon {
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-direction: column;
        text-align: center;
        position: absolute;
        top: 0; 
        left: -0;

        .bp3-icon-warning-sign {
            position: static;
        }

        .bp3-heading{
            font-size: 13px;
            margin-left: 20px;
        }
    } 
}


.meanScore {
    font-size: 1.2rem;
    text-align: center;
    margin-bottom: 1.75rem;
    position: relative;

    .bp3-intent-warning.bp3-callout-icon {
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;
        flex-direction: column;
        text-align: center;
        position: absolute;
        top: 0; 
        left: -0;
        
        .bp3-icon-warning-sign {
            position: static;
        }

        .bp3-heading{
            font-size: 13px;
            margin-left: 20px;
        }
    } 

}

.domain-wrapper__pill.bp3-card {
    height: 54px;   
}

.hgt32 {
    height: 32px; 
    display: block;   
}


.item-desc {
    display: flex;
    height: 54px; 
}

.slic-grid {
    margin-bottom: 16px;  
    span{
        display: flex;
        height: 54px;
        align-items: center;
        justify-content: center;
    }  
}

.domain-wrapper{ 
    height: 100%;
    position: relative;

    h4 {
        font-size: 16px;
        font-weight: bold;       
        color: white;
        background-color: var(--navy);
        height: 54px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        
    }
    &__pill {        
        border-left-width: 5px;
        border-left-style: solid;              
        min-height: 54px;
        text-align: center;
        padding: 8px; 
        display: flex;
        flex-direction: column;         
        justify-content: center;
        font-size: 15px;
        border-radius: 5px;
        margin: 12px 16px 16px 0;
        position: relative;

        .bp3-icon-warning-sign svg {
            position: absolute;
            right: 5px;
            top: 5px;
            width: 16px;
        }
    }

    &__pill span {
        display: inline-block;
        text-align: center;
        color: var(--black);
    }
}

.slider-outer {
    background-color: var(--dimgray);
    h4.school-title{
        background-color: var(--navy);
        color: white;
        height: 54px;
        margin-bottom: 1rem;        
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 15px;
        font-weight: bold;
        justify-content: center;
        span{
            font-size: 14px;
            font-weight: normal;
        }       
    }

    .slick-next {
        right: 8px;
        top: 29px;
        z-index: 99;
    }

    .slick-prev {
        left: 8px;
        top: 29px;
        z-index: 99;
    }

    .br-right{
        border-right: solid 1px #ddd;
    }

    .mean-score {
        display: flex;
        flex-direction: column;
        align-items: center;   

        &__pill {
            padding: 8px 12px;
            border-radius: 2px;
            font-size: 14px;
            font-weight: bold;  
            border-radius: 5px;
            margin-bottom: 1rem;

        }

        h5{
            font-size: 15px;
            text-align: center;
            margin-bottom: 1rem;
           
        }
    }

}

.total-score {        
    display: flex;
    flex-direction: column;
    align-items: center;   

    .bp3-icon-info-sign {
        position: absolute;
        right: 8px;
    }

    h4 {
        font-size: 16px;
        font-weight: bold;       
        color: white;
        background-color: var(--navy);
        height: 54px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-bottom: 1rem;
        width: 100%;  
        
        span{
            font-weight: normal;            
        }
    }

    &__pill {
        padding: 8px 12px;
        border-radius: 2px;
        font-size: 14px;
        font-weight: bold;  
        border-radius: 5px;
        margin-bottom: 1rem;
    } 

    .mean-score {
        display: flex;
        flex-direction: column;
        align-items: center;   

        &__pill {
            padding: 8px 12px;
            border-radius: 2px;
            font-size: 14px;
            font-weight: bold;  
            border-radius: 5px;
            margin-bottom: 1rem;

        }

        h5{
            font-size: 15px;
            text-align: center;
            margin-bottom: 1rem;
           
        }
    }
    .meanScore {
        .total-mean-score-value {
            span.bp3-icon-warning-sign {
                position: static;
            }
            h4.bp3-heading {
                height: auto;
                background-color: transparent;
                margin-left: 0;
            }
        }
    }
}

.no-data {
    margin: 0 auto;
    text-align: center;
    font-size: 16px;
    background: #f7f7f7;
    display: flex;
    width: 40%;
    align-items: center;
    justify-content: center;
    padding: 30px;
    margin-top: 24px;
    border-radius: 8px;
    border: solid 1px #ccc;
}


.slider-comp-report .slick-slide.slick-active{
    width:100% !important;
    }
    .slider-comp-report .slick-track{
    width:100% !important;
    }


    .ellipsis {
        width: 100px;              
        white-space: nowrap;        
        overflow: hidden;          
        text-overflow: ellipsis;  
      }